const OrderDetails = ({ subscription, game }) => {
  return (
    <div className="flex gap-4">
      <div
        className="h-48 rounded aspect-square"
        style={{
          backgroundImage: `url(${game.artwork})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="flex flex-col">
        <p className="text-xl text-white">{game.name}</p>
        <div>
          <p>
            <span className="text-gray-400">Slots:</span> {subscription.slots}
          </p>
          <p>
            <span className="text-gray-400">Days runtime:</span>{" "}
            {subscription.cycle}
          </p>
          <p>
            <span className="text-gray-400">GB RAM:</span>{" "}
            {subscription.ram / 1024}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
