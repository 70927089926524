import { Alert as AntdAlert } from "antd";

export function Alert({ block, message, variant, children }) {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <AntdAlert
        className={block ? "w-full" : ""}
        message={message}
        type={variant}
        showIcon
      ></AntdAlert>
    </div>
  );
}

export function RequestGenericErrorAlert({ error }) {
  const generic = error?.response?.data?.generic ?? "unexpected_error";

  return <Alert block variant={"error"} message={generic} />;
}
