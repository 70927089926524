import ProList from "@ant-design/pro-list";
import { Image } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { CreateGame } from "./widgets/CreateGame";
import { QueryLoader } from "../../components/utility/QueryLoader";

export function Games() {
  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  return (
    <div className="w-full h-full">
      <QueryLoader query={games_query}>
        <ProList
          toolBarRender={() => [<CreateGame />]}
          dataSource={games}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 3,
            xxl: 3,
          }}
          metas={{
            title: {
              dataIndex: "name",
              render: (text, record) => (
                <a href={`/games/${record.id}`}>{text} [{record.image}]</a>
              ),
            },
            description: {
              dataIndex: "description",
            },
            content: {
              render: (text, record) => (
                <div className="w-full rounded aspect-square" preview={"false"} style={{
                  backgroundImage: `url(${record.artwork})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }} />
              ),
            },
          }}
          headerTheme="dark"
        />
      </QueryLoader>
    </div>
  );
}
const GamesForm = Games
export default GamesForm 
