import { useTranslation } from "react-i18next";
import { Footer } from "../homepage/Footer";
import { Navbar } from "../homepage/Navbar";

export function Tos() {
    const { t } = useTranslation();
    const tos = {
        introduction: {
            item1: "",
            item2: "",
            item3: "",
            item4: "",
        },
        requirements: {
            item1: "",
            item2: "",
            item3: ""
        },
        serverservices: {
            item1: ""
        },
        services: {
            item1: "",
            item2: "",
            item3: "",
            item4: ""
        },
        serferatyaccount: {
            item1: "",
            item2: "",
            item3: "",
            item4: ""
        },
        access: {
            item1: ""
        },
        infringement: {
            item1: "",
            item2: {
                subitem1: '',
                subitem2: '',
                subitem3: '',
                subitem4: '',
                subitem5: '',
                subitem6: '',
                subitem7: ''
            },
            item3: ""
        },
        prepayment: {
            item1: "",
            item2: "",
            item3: "",
            item4: "",
            item5: "",
            item6: "",
            item7: "",
            item8: "",
            item9: "",
            item10: "",
            item11: "",
            item12: "",
            item13: "",
            item14: "",
        },
        price: {
            item1: "",
            item2: "",
            item3: ""
        },
        deletion: {
            item1: ""
        },
        privacypolicy: {
            item1: ""
        },
        customersupport: {
            item1: ""
        },
        warranty: {
            item1: ""
        },
        liability: {
            item1: ""
        },
        remedy: {
            item1: "",
            item2: ""
        },
        warrantyclaims: {
            item1: ""
        },
        indemnification: {
            item1: ""
        },
        miscellaneous: {
            item1: "",
            item2: "",
            item3: "",
            item4: "",
            item5: "",
            item6: "",
            item7: "",
            item8: "",
            item9: "",
            item10: "",
            item11: "",
            item12: "",
            item13: "",
            item14: "",
            item15: "",
            item16: "",
            item17: "",
            item18: "",
            item19: "",
            item20: "",
            item21: "",
            item22: "",
            item23: "",
            item24: "",
            item25: "",
            item26: "",
            item27: "",
            item28: "",
            item29: "",
        },
    };
    return (
        <div className=" bg-background space-y-12">
            <Navbar />
            <section className="container mx-auto p-4">
                <h1 className="text-white border-b-4 border-violet inline-block text-[2rem]">{t(`tos`)}</h1>
                <h1 className="text-white text-[24px]">SERFERATY</h1>
                <p className="text-white">{t(`tos.hereinafter`)}</p>
                <p className="text-white">{t(`tos.managingdirectors`)}</p>
                <p className="text-white">{t(`tos.date`)}</p>
                {Object.keys(tos).map((key, index) => (
                    <div className="flex flex-col flex-shrink-0 gap-4" key={index}>
                        <h2 className="text-white">
                            <span className="text-light-1 font-bold">{`${index + 1}`}</span> . {`${t(`tos.${key}`)}`}
                        </h2>
                        {/* {Object.keys(tos[key]).map((item) => (
                            <p className="text-white">
                                {t(`tos.${key}.${item}`)}
                            </p>
                        ))} */}
                        {typeof tos[key] === "object" ? (
                            <div className="pl-4">
                                {Object.keys(tos[key]).map((subkey) => (
                                    <div key={`${key}-${subkey}`}>
                                        <h3 className="text-white">{t(`tos.${key}.${subkey}`)}</h3>
                                        {typeof tos[key][subkey] === "object" ? (
                                            <div className="pl-4">
                                                {Object.keys(tos[key][subkey]).map((subsubkey) => (
                                                    <li key={`${key}-${subkey}-${subsubkey}`} className="text-white">{t(`tos.${key}.${subkey}.${subsubkey}`)}</li>
                                                ))}
                                            </div>
                                        ) : (
                                            <p className="text-white">{tos[key][subkey]}</p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-white">{tos[key]}</p>
                        )}
                    </div>
                ))}
            </section>
            <Footer />
        </div>

    );
}