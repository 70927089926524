import { Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useConsole } from "..";
import { useUser } from "../../authentication/user";
import { IF } from "../../components/adhoc/IF";
import { status_color_map } from "./Dashboard";

export function ServerThumbnail() {
  const { subscription, informations } = useConsole();
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <div
      className="p-4 space-y-2 bg-white border-none"
      style={{
        borderRadius: "0.10rem",
      }}
    >
      <div className="flex items-center gap-2">
        <div
          className="h-12 rounded aspect-square"
          style={{
            backgroundImage: `url(${subscription?.game.artwork})`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="flex flex-col items-start">
          <span className="text-lg">{subscription?.game.name}</span>
          <Tag color={status_color_map[informations.status]}>
            {informations.status}
          </Tag>
        </div>
      </div>
      <div className="flex flex-col font-bold">
        {subscription.modpack && (
          <span>
            {t("modpack")}: {subscription.modpack}
          </span>
        )}
      </div>
      <div className="flex flex-col font-bold">
        <span>
          {t("server_ip")}:{" "}
          <Typography.Text copyable>
            {`${informations.ip ?? "localhost"}:${
              informations.ports?.game ?? "n/a"
            }`}
          </Typography.Text>
        </span>
        <span>
          {t("server_port")}: {informations.ports?.game ?? "n/a"}
        </span>
        <IF that={informations.ports?.rcon}>
          <span>
            {t("RCON_port")}: {informations.ports?.rcon ?? "n/a"}
          </span>
        </IF>
        <IF that={user.role === "based"}>
          <span>server_fs_id: {subscription?.server_fs_id ?? "n/a"}</span>
          <span>
            container: {subscription?.container.substring(0, 4) ?? "n/a"}
          </span>
        </IF>
        <IF that={informations.ports?.query}>
          <span>
            {t("query_port")}: {informations.ports?.query ?? "n/a"}
          </span>
        </IF>
      </div>
    </div>
  );
}
