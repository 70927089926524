import { useTranslation } from "react-i18next";

export function Footer() {
  const { t } = useTranslation();
  const socials = {
    // facebook: {
    //   url: "https://www.facebook.com/",
    //   icon: "/assets/socials/facebook.svg",
    // },
    twitter: {
      url: "https://x.com/serferaty",
      icon: "/assets/socials/twitter.svg",
    },
    // instagram: {
    //   url: "https://www.instagram.com/",
    //   icon: "/assets/socials/instagram.svg",
    // },
    // youtube: {
    //   url: "https://www.youtube.com/",
    //   icon: "/assets/socials/youtube.svg",
    // },
    // github: {
    //   url: "https://www.github.com/",
    //   icon: "/assets/socials/github.svg",
    // },
    discord: {
      url: "https://discord.gg/nWJ6qDGs",
      icon: "/assets/socials/discord.svg",
    },
    tiktok: {
      url: "https://www.tiktok.com/@serferaty?is_from_webapp=1&sender_device=pc",
      icon: "/assets/socials/tiktok.svg",
    },
  };

  const sections = {
    products: {
      item1: "/account",
      item2: "/ourprices",
    },
    company: {
      item1: "/aboutus",
      item2: "/ourstory",
      item7: "/follow",
      item8: "/contact",
      item9: "/datacenter",
    },
    technology: {
      item1: "/authentication",
      item2: "/discord",
    },
    support: {
      item1: "/faqs",
      item2: "/privacy",
      item3: "/refund",
      item4: "/tos",
      item5: "/toc",
    },
  };

  return (
    <div className="w-full py-16 border-t-2 border-light-1">
      <div className="container flex flex-wrap gap-4 p-4 mx-auto md:justify-between">
        <div className="flex-shrink-0 space-y-4">
            <img className="h-16" src="/logo/logo.png" alt="logo" />
          <div className="flex gap-2">
            {Object.keys(socials).map((key) => (
              <a href={socials[key].url} key={key} target="_blank"  rel="noreferrer">
                <img className="h-8" src={socials[key].icon} alt="social link" />
              </a>
            ))}
          </div>
        </div>
        {Object.keys(sections).map((key) => (
          <div className="flex flex-col flex-shrink-0 gap-4" key={key}>
            <h1 className="text-xl font-normal text-white capitalize">
              {t(`footer.${key}`)}
            </h1>
            {Object.keys(sections[key]).map((item) => (
              <a
                className="text-white text-gray-500 capitalize text-md"
                href={sections[key][item]}
                key={item}
              >
                {t(`footer.${key}.${item}`)}
              </a>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
