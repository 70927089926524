import { Button, Card } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MenuUserThumbnail } from "./MenuUserThumbnail";
import { IF } from "../../components/adhoc/IF";

import AccountIcon from "@mui/icons-material/AccountBoxRounded";
import ServersIcon from "@mui/icons-material/DnsRounded";
import InvoicesIcon from "@mui/icons-material/ReceiptRounded";
import PackagesIcon from "@mui/icons-material/PriceChangeRounded";
import CouponsIcon from "@mui/icons-material/LocalOfferRounded";
import { useUser } from "../../authentication/user";

export function Menu() {
  const { t } = useTranslation();
  const { granted } = useUser();

  return (
    <div className="flex flex-col w-screen h-full max-w-xs gap-2">
      <div
        className="p-4 bg-white border-none"
        style={{
          borderRadius: "0.10rem",
        }}
      >
        <MenuUserThumbnail />
      </div>
      <div
        className="flex-grow p-4 bg-white border-none"
        style={{
          borderRadius: "0.10rem",
        }}
      >
        <div className="flex flex-col gap-4">
          <Link to="">
            <Button block type="primary" className="flex">
              <ServersIcon />
              <span className="flex-grow font-bold capitalize">
                {t("account_servers")}
              </span>
            </Button>
          </Link>
          <IF that={granted("read", "customer")}>
            <Link to="customers">
              <Button block type="primary" className="flex">
                <AccountIcon />
                <span className="flex-grow font-bold capitalize">
                  {t("account.customers")}
                </span>
              </Button>
            </Link>
          </IF>
          <Link to="invoices">
            <Button block type="primary" className="flex">
              <InvoicesIcon />
              <span className="flex-grow font-bold capitalize">
                {t("account.invoices")}
              </span>
            </Button>
          </Link>
          <IF that={granted("create", "package")}>
            <Link to="packages">
              <Button block type="primary" className="flex">
                <PackagesIcon />
                <span className="flex-grow font-bold capitalize">
                  {t("account.packages")}
                </span>
              </Button>
            </Link>
          </IF>
          <IF that={granted("create", "coupon")}>
            <Link to="coupons">
              <Button block type="primary" className="flex">
                <CouponsIcon />
                <span className="flex-grow font-bold capitalize">
                  {t("account.coupons")}
                </span>
              </Button>
            </Link>
          </IF>
          <IF that={granted("read", "transaction")}>
            <Link to="transactions">
              <Button block type="primary" className="flex">
                <PackagesIcon />
                <span className="flex-grow font-bold capitalize">
                  {t("account.transaction")}
                </span>
              </Button>
            </Link>
          </IF>
          <IF that={granted("create", "game")}>
            <Link to="games">
              <Button block type="primary" className="flex">
                <PackagesIcon />
                <span className="flex-grow font-bold capitalize">
                  {t("account.games")}
                </span>
              </Button>
            </Link>
          </IF>
          <IF that={granted("create", "price")}>
            <Link to="prices">
              <Button block type="primary" className="flex">
                <PackagesIcon />
                <span className="flex-grow font-bold capitalize">
                  {t("account.prices")}
                </span>
              </Button>
            </Link>
          </IF>
        </div>
      </div>
    </div>
  );
}
