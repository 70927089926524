import { useTranslation } from "react-i18next";
import { GamesWindowSlider } from "./widgets/GamesWindowSlider";

export function Features() {
  const { t } = useTranslation();
  return (
    <div className="container flex flex-col items-center justify-between w-full gap-4 p-4 mx-auto md:gap-8 lg:flex-row">
      <div className="flex-grow space-y-16">
        <h1 className="text-5xl text-white xl:text-6xl">
        {t(`feature.heading.item1`)} <span className="text-purple-600">{t(`feature.heading.item2`)}</span> {t(`feature.heading.item3`)}{" "}
          <span className="text-purple-600">{t(`feature.heading.item4`)}</span>
        </h1>
        <p className="text-2xl text-white lg:text-3xl xl:text-4xl">
        {t(`feature.sampletext`)}
        </p>
      </div>
      <GamesWindowSlider />
    </div>
  );
}
