import { Carousel } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { QueryLoader } from "../components/utility/QueryLoader";

function GameThumbnail({ game }) {
  return (
    <Link to={`/games/${game.name}`}>
      <div
        className="h-48 my-2 rounded-md md:mx-2 md:my-0"
        style={{
          backgroundImage: `url(${game.artwork})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center justify-center w-full h-full rounded-md backdrop-brightness-50">
          <h1 className="text-lg text-white">{game.name}</h1>
        </div>
      </div>
    </Link>
  );
}

export function GamesThumbnailsSlider() {
  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  return (
    <QueryLoader query={games_query}>
      <div className="container w-full px-4 mx-auto -mt-24" style={{}}>
        <Carousel
          className="hidden md:block"
          autoplay
          slidesToShow={games.length > 4 ? 4 : games.length}
          draggable
        >
          {games.map((game) => (
            <GameThumbnail key={game.name} game={game} />
          ))}
        </Carousel>
        <Carousel
          className="md:hidden"
          dotPosition="right"
          autoplay
          slidesToShow={games.length > 4 ? 4 : games.length}
          draggable
        >
          {games.map((game) => (
            <GameThumbnail key={game.name} game={game} />
          ))}
        </Carousel>
      </div>
    </QueryLoader>
  );
}
