import {  useMemo } from "react";
import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from "react-query";

export function QueryClientProvider({ children }) {
  const client = useMemo(() => new QueryClient({
    
  }), []);

  return (
    <ReactQueryClientProvider client={client}>
      {children}
    </ReactQueryClientProvider>
  );
}
