import {
  ProFormDigit,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useQuery } from "react-query";
import axios from "axios";

export function UpdatePackageForm({ value, form, error }) {
  const { t } = useTranslation();

  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  return (
    <Form form={form} layout="vertical" initialValues={value}>
      <ProFormText
        name="name"
        label={t("package.name")}
        rules={[{ required: true }]}
        validateStatus={_.get(error, "name") ? "error" : undefined}
        help={_.get(error, "name") ? error.name : undefined}
        placeholder={t("name_placeholder")}
      />
      <ProFormSelect
        name="game"
        label={t("game")}
        options={games.map((game) => ({
          label: game.name,
          value: game._id,
        }))}
        rules={[{ required: true, message: t("game_required") }]}
        validateStatus={_.get(error, "game") ? "error" : undefined}
        help={_.get(error, "game") ? error.game : undefined}
        placeholder={t("game_placeholder")}
      />
      <ProFormDigit
        name="slots"
        label={t("package.slots")}
        rules={[{ required: true, message: t("slots_required") }]}
        validateStatus={_.get(error, "slots") ? "error" : undefined}
        help={_.get(error, "slots") ? error.slots : undefined}
        placeholder={t("slots_placeholder")}
      />
      <ProFormDigit
        name="ram"
        label={t("package.ram")}
        rules={[{ required: true, message: t("ram_required") }]}
        validateStatus={_.get(error, "ram") ? "error" : undefined}
        help={_.get(error, "ram") ? error.ram : undefined}
        placeholder={t("ram_placeholder")}
      />
      <ProFormDigit
        name="cycle"
        label={t("package.cycle")}
        rules={[{ required: true, message: t("cycle_required") }]}
        validateStatus={_.get(error, "cycles") ? "error" : undefined}
        help={_.get(error, "cycles") ? error.cycles : undefined}
        placeholder={t("cycles_placeholder")}
      />
      <ProFormDigit
        name="price"
        label={t("package.price")}
        rules={[{ required: true, message: t("price_required") }]}
        validateStatus={_.get(error, "price") ? "error" : undefined}
        help={_.get(error, "price") ? error.price : undefined}
        placeholder={t("price_placeholder")}
      />
    </Form>
  );
}
