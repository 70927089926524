import { Input } from "antd";
import { useTranslation } from "react-i18next";

export function GamesSearch({value, onChange}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center -mt-7">
      <Input
        value={value}
        onChange={onChange}
        className="max-w-2xl px-4 py-2 text-lg bg-white rounded text-background"
        placeholder={t("game.name")}
      ></Input>
    </div>
  );
}
