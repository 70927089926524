import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { QueryLoader } from "../components/utility/QueryLoader";
import { createContext, useContext, useEffect, useMemo } from "react";
import DashboardIcon from "@mui/icons-material/SpeedRounded";
import { Route, Routes } from "react-router-dom";
import { MenuUserThumbnail } from "../account/widgets/MenuUserThumbnail";
import { Dashboard } from "./widgets/Dashboard";
import { Logs } from "./widgets/Logs";
import { Mods } from "./widgets/Mods";
import { Modpacks } from "./widgets/Modpacks";
import { Worlds } from "./widgets/Worlds";
import { RCONConsole } from "./widgets/RCON";
import { Button, Collapse } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ServerThumbnail } from "./widgets/ServerThumbnail";
import { DeleteSubscription } from "./widgets/DeleteSubscription";
import { useAuthentication } from "../authentication";

const game_ui_configuration_map = {
  minecraft: {
    modpacks: false,
    worlds: true,
  },
  "minecraft-ftb": {
    modpacks: true,
    worlds: true,
  },
  rust: {
    modpacks: false,
    worlds: false,
  },
  ark: {
    modpacks: false,
    worlds: false,
  },
};

const context = createContext();

export const useConsole = () => useContext(context);

export function Menu() {
  const { t } = useTranslation();
  const { configuration, subscription } = useConsole();

  return (
    <div className="flex flex-col w-screen h-full max-w-xs gap-2">
      <div
        className="p-4 bg-white border-none"
        style={{
          borderRadius: "0.10rem",
        }}
      >
        <MenuUserThumbnail />
      </div>
      <ServerThumbnail />
      <div
        className="flex flex-col flex-grow bg-white divide-y divide-gray-200"
        style={{
          borderRadius: "0.10rem",
        }}
      >
        <Collapse defaultActiveKey={["1", "3"]}>
          <Collapse.Panel
            header={t("server.informations").toLocaleUpperCase()}
            key="1"
          >
            <Link to="">
              <Button block type="text" className="space-x-4 text-left">
                <DashboardIcon />
                <span className="flex-grow capitalize">
                  {t("informations.dashboard")}
                </span>
              </Button>
            </Link>
            <Link to="logs">
              <Button block type="text" className="space-x-4 text-left">
                <DashboardIcon />
                <span className="flex-grow capitalize">
                  {t("informations.logs")}
                </span>
              </Button>
            </Link>
          </Collapse.Panel>
          {/* <Collapse.Panel
            header={t("server.tools").toLocaleUpperCase()}
            key="3"
          >
            <IF that={configuration[subscription.game.image].modpacks}>
              <Link to="mods">
                <Button block type="text" className="space-x-4 text-left">
                  <ServersIcon />
                  <span className="flex-grow capitalize">
                    {t("tools.mods_manager")}
                  </span>
                </Button>
              </Link>
            </IF>
            <Link to="worlds">
              <Button block type="text" className="space-x-4 text-left">
                <ServersIcon />
                <span className="flex-grow capitalize">
                  {t("tools.world_manager")}
                </span>
              </Button>
            </Link>
            <Link to="rcon">
              <Button block type="text" className="space-x-4 text-left">
                <ServersIcon />
                <span className="flex-grow capitalize">{t("tools.rcon")}</span>
              </Button>
            </Link>
          </Collapse.Panel>
        */}
        </Collapse>
        {/* <IF that={configuration[subscription.game.image].modpacks}>
          <Link to="modpacks">
            <Button
              block
              type="text"
              size="large"
              className="space-x-4 text-left"
            >
              <ServersIcon />
              <span className="flex-grow font-bold capitalize">
                {t("modpacks_installer")}
              </span>
            </Button>
          </Link>
        </IF> */}
        {/* <SwitchGame /> */}
        <DeleteSubscription />
      </div>
    </div>
  );
}

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/logs" element={<Logs />} />
      <Route path="/mods" element={<Mods />} />
      <Route path="/modpacks" element={<Modpacks />} />
      <Route path="/worlds" element={<Worlds />} />
      <Route path="/rcon" element={<RCONConsole />} />
    </Routes>
  );
}

export function Console() {
  const { subscription_id } = useParams();
  const subscriptions_query = useQuery("subscriptions", () =>
    axios.get("/subscriptions")
  );
  const navigate = useNavigate();
  const subscriptions = subscriptions_query.data?.data || [];
  const subscription = useMemo(
    () =>
      subscriptions.find(
        (subscription) => subscription._id === subscription_id
      ),
    [subscriptions_query, subscription_id]
  );

  useEffect(() => {
    if (!subscription) {
      navigate("/account");
    }
  }, [subscription]);

  // subscription generics
  const informations_query = useQuery(
    ["server_informations", subscription_id],
    () => axios.get("/servers/" + subscription_id + "/informations")
  );

  const start = useMutation(
    () => axios.post("/servers/" + subscription_id + "/actions/start"),
    {
      onSuccess: () => {
        informations_query.refetch();
      },
    }
  );
  const stop = useMutation(
    () => axios.post("/servers/" + subscription_id + "/actions/stop"),
    {
      onSuccess: () => {
        informations_query.refetch();
      },
    }
  );
  const restart = useMutation(
    () => axios.post("/servers/" + subscription_id + "/actions/restart"),
    {
      onSuccess: () => {
        informations_query.refetch();
      },
    }
  );

  const { is_authenticated } = useAuthentication();

  if (!is_authenticated) {
    return <Navigate to="/authentication" />;
  }

  if (informations_query.isLoading || subscriptions_query.isLoading)
    return null;

  console.log(informations_query.data?.data);

  return (
    <QueryLoader query={informations_query}>
      <context.Provider
        value={{
          subscriptions_query,
          subscription,
          informations_query,
          informations: informations_query.data?.data,
          start,
          stop,
          restart,
          configuration: game_ui_configuration_map,
        }}
      >
        <QueryLoader query={subscriptions_query}>
          {subscription ? (
            <div className="container flex items-stretch h-full gap-4 mx-auto my-8">
              <Menu subscription={subscription} />
              <div className="flex-grow">
                <Router />
              </div>
            </div>
          ) : (
            <div>subscription not found</div>
          )}
        </QueryLoader>
      </context.Provider>
    </QueryLoader>
  );
}
