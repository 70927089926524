import { Avatar } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { CreatePrice } from "./widgets/CreatePrice";
import { QueryLoader } from "../../components/utility/QueryLoader";
import { useMemo, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import ProTable from "@ant-design/pro-table";
import { DeletePackage } from "./widgets/DeletePackage";
import { UpdatePackage } from "./widgets/UpdatePackage";

function PackagesList() {
  const { t } = useTranslation();
  const [filter, set_filter] = useState({});

  const prices_query = useQuery("prices", () => axios.get("/prices"));

  const packages = prices_query.data?.data || [];

  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  const id_game_map = _.keyBy(games, "_id");

  const filtered_packages = useMemo(() => {
    if (!filter.game) {
      return packages;
    }
    return packages.filter((pkg) => {
      return pkg.game === games[filter.game]?._id;
    });
  }, [filter, packages]);

  return (
    <QueryLoader query={prices_query}>
      <ProTable
        headerTitle={t("packages")}
        toolBarRender={() => [<CreatePrice />]}
        dataSource={filtered_packages}
        search={{
          filterType: "light",
        }}
        request={set_filter}
        columns={[
          {
            title: t("package.game"),
            dataIndex: "game",
            valueType: "select",
            valueEnum: games.map((game) => game.name),
            render: (_, record) => {
              return (
                <div className="flex items-center gap-2">
                  <Avatar
                    shape="square"
                    size="small"
                    src={id_game_map[record.game]?.artwork}
                  />
                  <span>{id_game_map[record.game]?.name}</span>
                </div>
              );
            },
          },
          {
            title: t("package.name"),
            dataIndex: "name",
            search: false,
          },
          {
            title: t("package.price"),
            dataIndex: "price",
            search: false,
          },
          {
            title: t("package.cycle"),
            dataIndex: "cycke",
            search: false,
          },
          {
            title: t("package.slots"),
            dataIndex: "slots",
            search: false,
          },
          {
            title: t("package.ram"),
            dataIndex: "ram",
            search: false,
          },
          {
            title: t("package.actions"),
            dataIndex: "actions",
            search: false,
            render: (_, record) => {
              return (
                <div className="flex gap-2">
                  <UpdatePackage package={record} />
                  <DeletePackage package={record} />
                </div>
              );
            },
          },
        ]}
      />
    </QueryLoader>
  );
}

export function Prices() {
  return (
    <div className="flex-grow">
      <PackagesList />
    </div>
  );
}
