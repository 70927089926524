import { Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import DownIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useState } from "react";

export function LocaleSwitcher() {
  const { t, i18n } = useTranslation();

  const supported_locales = {
    en: {
      label: "locale.en",
      value: "en",
      currency: "USD.en",
    },
    ar: {
      label: "locale.ar",
      value: "ar",
      currency: "USD.ar",
    },
    ur: {
      label: "locale.ur",
      value: "ur",
      currency: "USD.ur",
    },
  }

  const locale = supported_locales[i18n.language] ?? supported_locales.en;  
  // console.log("hello locale ", locale)
  // console.log("hello i18n.language",i18n.language)

  return (
    <Dropdown
      overlay={
        <div className="py-2 space-y-4 text-white rounded bg-background">
          {Object.values(supported_locales).map((locale) => (
            <div
              className="px-4 capitalize duration-200 cursor-pointer text-inherit hover:text-light-1"
              key={locale.value}
              onClick={() => {
                i18n.changeLanguage(locale.value);
              }}
            >
              {`${t(locale.label)}`}
            </div>
          ))}
        </div>
      }
    >
      <a className="flex items-center gap-2 capitalize text-inherit">
        <span className="flex-grow truncate ">{`${t(locale.label)}`}</span>
        <DownIcon />
      </a>
    </Dropdown>
  );
}
