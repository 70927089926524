import ProTable from "@ant-design/pro-table";
import axios from "axios";
import { useQuery } from "react-query";
import { QueryLoader } from "../../components/utility/QueryLoader";
import { useMemo, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export function Customers() {
  const { t } = useTranslation();
  const [filter, set_filter] = useState({});

  const customers_query = useQuery("customers", () => axios.get("/customers"));
  const customers = customers_query.data?.data || [];

  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  const id_game_map = _.keyBy(games, "_id");

  const filtered_customers = useMemo(() => {
    if (!filter.game) {
      return customers;
    }
    return customers.filter((pkg) => {
      return pkg.game === games[filter.game]?._id;
    });
  }, [filter, customers]);

  return (
    <QueryLoader query={customers_query}>
      <ProTable
        headerTitle={t("customers")}
        dataSource={filtered_customers}
        search={{
          filterType: "light",
        }}
        request={set_filter}
        columns={[
          {
            title: t("customer.name"),
            dataIndex: "name",
          },
          {
            title: t("customer.email"),
            dataIndex: "email",
          },
          {
            title: t("customer.phone"),
            dataIndex: "phone",
          },
          {
            title: t("customer.subscriptions"),
            dataIndex: "subscriptions",
            search: false,
          }
        ]}
      />
    </QueryLoader>
  );
}
