import { useTranslation } from "react-i18next";

export function Hero() {
  const {t} = useTranslation()

  return (
    <div
      className="flex items-center justify-center max-h-screen mx-auto rounded-b"
      style={{
        backgroundImage: `url(/assets/games_hero_background.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "75vh",
      }}
    >
      <h1 className="text-white capitalize text-8xl">{t('games.headline')}</h1>
    </div>
  );
}
