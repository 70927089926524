import ProList from "@ant-design/pro-list";
import { Image, Tag } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { CreateCoupon } from "./widgets/CreateCoupon";
import { QueryLoader } from "../../components/utility/QueryLoader";
import { useMemo, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import ProTable from "@ant-design/pro-table";
import { DeleteCoupon } from "./widgets/DeleteCoupon";

const coupon_status_color_map = {
  active: "green",
  expired: "red",
};

function CouponsList() {
  const { t } = useTranslation();
  const [filter, set_filter] = useState({});

  const coupons_query = useQuery("coupons", () => axios.get("/coupons"));
  const coupons = coupons_query.data?.data || [];

  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  const id_game_map = _.keyBy(games, "_id");

  const filtered_coupons = useMemo(() => {
    if (!filter.status) {
      return coupons;
    }
    return coupons.filter((pkg) => {
      return pkg.status === Object.keys(coupon_status_color_map)[filter.status];
    });
  }, [filter, coupons]);

  return (
    <QueryLoader query={coupons_query}>
      <ProTable
        headerTitle={t("coupons")}
        toolBarRender={() => [<CreateCoupon />]}
        dataSource={filtered_coupons}
        search={{
          filterType: "light",
        }}
        request={set_filter}
        columns={[
          {
            title: t("coupon.code"),
            dataIndex: "code",
            search: false,
          },
          {
            title: t("coupon.type"),
            dataIndex: "type",
            valueType: "select",
            valueEnum: {
              percentage: t("coupon.type.percentage"),
              fixed: t("coupon.type.fixed"),
            },
          },
          {
            title: t("coupon.value"),
            dataIndex: "value",
            search: false,
          },
          {
            title: t("coupon.status"),
            dataIndex: "status",
            valueType: "select",
            valueEnum: Object.keys(coupon_status_color_map),
            render: (text, record) => {
              return (
                <Tag color={coupon_status_color_map[record.status]}>
                  {t(`coupon.status.${record.status}`)}
                </Tag>
              );
            },
          },
          {
            title: t("coupon.created_at"),
            dataIndex: "createdAt",
            search: false,
            render: (text, record) => {
              return new Date(record.createdAt).toLocaleString();
            },
          },
          {
            title: t("coupon.expiry"),
            dataIndex: "expiry",
            search: false,
            render: (text, record) => {
              return new Date(record.expiry).toLocaleString();
            },
          },
          {
            title: t("coupon.game"),
            dataIndex: "game",
            search: true,
            valueType: "select",
            valueEnum: games.map((game) => {
              return {
                value: game._id,
                text: game.name,
              };
            }),
            render: (text, record) => {
              return record.games?.map((game) => <Tag>
                {id_game_map[game]?.name}
              </Tag>)  ?? t("coupon.game.all");
            }
          },
          {
            title: t("coupon.actions"),
            dataIndex: "actions",
            search: false,
            render: (text, record) => {
              return <DeleteCoupon coupon={record} />;
            },
          }
        ]}

      />
    </QueryLoader>
  );
}

export function Coupons() {
  return (
      <div className="flex-grow">
        <CouponsList />
      </div>
  );
}
