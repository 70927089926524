import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./style/index.css";
import "./style/theme/antd.css";
import "@ant-design/pro-components/dist/components.css";

import en_US from "antd/lib/locale/en_US";
import ar_EG from "antd/lib/locale/ar_EG";
import reportWebVitals from "./vitals";
import Homepage from "./homepage";
import Authentication, { AuthenticationProvider } from "./authentication";
import axios from "axios";
import { QueryClientProvider } from "./components/adhoc/QueryClientProvider";
import i18n from "./translation";
import { UserProvider } from "./authentication/user";
import { ConfigProvider } from "antd";
import { Router } from "./router";
import { Tos } from "./tos";
import { Privacy } from "./privacy";
import { I18nextProvider } from "react-i18next";
import { Refund } from "./refund";
import { OurStory } from "./ourstory";
import { AboutUs } from "./aboutuspage";
import { FAQs } from "./FAQs";
import { OurPrices } from "./ourprices";
import { FollowUs } from "./followus";
import { ContactUs } from "./contactus";
import { Toc } from "./toc";
import { Discord } from "./discord";
import { DataCenter } from "./datacenter";

const environment_baseURL_map = {
  "109.161.175.184": "http://109.161.175.184:1337",
  localhost: "http://localhost:1337",
  "www.serferaty.com": "https://gmghost.serferaty.com",
  "serferaty.com": "https://gmghost.serferaty.com",
};

console.log("check adil  environment_baseURL_map", environment_baseURL_map);
console.log("check window.location.hostname", window.location.hostname);
console.log("map url", environment_baseURL_map[window.location.hostname]);
axios.defaults.baseURL = environment_baseURL_map[window.location.hostname];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <I18nextProvider i18n={i18n}>
      <ConfigProvider locale={i18n.language === "ar" ? ar_EG : en_US}>
        <QueryClientProvider>
          <AuthenticationProvider>
            <BrowserRouter>
              <UserProvider>
                <div>
                  <Routes>
                    <Route
                      path="/authentication/*"
                      element={<Authentication />}
                    />
                    <Route path="/" element={<Homepage />} />
                    <Route path="/ourprices" element={<OurPrices />}></Route>
                    <Route path="/aboutus" element={<AboutUs />} />
                    <Route path="/ourstory" element={<OurStory />}></Route>
                    <Route path="/follow" element={<FollowUs />}></Route>
                    <Route path="/contact" element={<ContactUs />}></Route>
                    <Route path="/datacenter" element={<DataCenter />}></Route>
                    <Route path="/discord" element={<Discord />}></Route>
                    <Route path="/faqs" element={<FAQs />}></Route>
                    <Route path="/privacy" element={<Privacy />}></Route>
                    <Route path="/refund" element={<Refund />}></Route>
                    <Route path="/tos" element={<Tos />}></Route>
                    <Route path="/toc" element={<Toc />}></Route>
                    <Route path="*" element={<Router />} />
                  </Routes>
                </div>
              </UserProvider>
            </BrowserRouter>
          </AuthenticationProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </I18nextProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
