import { useTranslation } from "react-i18next";
import { Footer } from "../homepage/Footer";
import { Navbar } from "../homepage/Navbar";
import { ContactCard } from "../components/controllerpursuant/ContactCard";

export function DataCenter() {
    const { t } = useTranslation();
    return (
        <div className=" bg-background space-y-12">
            <Navbar />
            <section className="container mx-auto p-4 flex justify-between items-start gap-[2rem]">
                {/* Container */}
                <div>
                    <div>
                        <h1 className="text-white text-[3rem] border-b-4 border-light-1 inline-block">{t(`datacenter`)}</h1>
                        <h1 className="text-white text-[24px]">{t(`datacenter.definitions`)}</h1>
                        <div className="text-[#E4E4E4] bg-[#2F1F58] border-l-4 border-light-1 shadow-bsh flex justify-arround items-center gap-8 pt-3 px-3 mb-6">
                            <h1 className="text-light-1 text-[2rem] mt-2">?</h1>
                            <h1 className="text-[#7D7D7D] text-[2rem] font-thin ">|</h1>
                            <p>
                            {t(`datacenter.header`)}
                            </p>
                        </div>
                        <p className="text-white">
                            {t(`datacenter.paragraph`)}
                        </p>
                        <div className="text-[#E4E4E4] bg-[#2F1F58] border-l-4 border-light-1 shadow-bsh flex justify-arround items-center gap-8 pt-3 px-3 mb-6">
                            <h1 className="text-light-1 text-[2rem] mt-2">?</h1>
                            <h1 className="text-[#7D7D7D] text-[2rem] font-thin ">|</h1>
                            <p>
                            {t(`location.header`)}
                            </p>
                        </div>
                        <p className="text-white">
                            {t(`location.paragraph`)}
                        </p>
                    </div>
                </div>
                <ContactCard />
            </section>

            <Footer />
        </div>
    );
}