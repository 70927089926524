import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

export function DeleteCoupon({ coupon: value }) {
  const { t } = useTranslation();
  const client = useQueryClient();
  const delete_coupon = useMutation(
    () => axios.delete("/coupons/" + value._id),
    {
      onSuccess: () => {
        client.invalidateQueries("coupons");
      },
    }
  );

  return (
    <>
      <Button
        type="danger"
        className="space-x-4 text-left"
        loading={delete_coupon.isLoading}
        onClick={() => delete_coupon.mutate()}
      >
        <span className="flex-grow">{t("coupon.delete")}</span>
      </Button>
    </>
  );
}
