import { Avatar } from "antd";
import { useUser } from "../../authentication/user"

export function MenuUserThumbnail() {
  const { user } = useUser()

  return (
    <div className="flex items-center gap-2">
      <Avatar className="ring ring-light-2" src={user?.avatar ?? "https://www.cbns.org.au/wp-content/uploads/2017/05/img_placeholder_avatar.jpg"}></Avatar>
      <div className="flex-grow">
        <h1 className="m-0 text-inherit">{user?.name ?? "user"}</h1>
        <h2 className="m-0 text-inherit">{user?.email || user?.phone}</h2>
      </div>
    </div>
  );
}
