import { Navigate, Route, Routes } from "react-router-dom";
import { Games } from "../games";
import { Game } from "../game";
import { Footer } from "../homepage/Footer";
import { Navbar } from "../homepage/Navbar";
import { Account } from "../account";
import GamesForm from "../account/games";
import Checkout from "../checkout";
import { Console } from "../console";

export function Router() {
  return (
    <div className="flex flex-col w-full overflow-y-auto bg-background">
      <div className="container mx-auto">
        <Navbar />
      </div>
      <div
        className="flex-grow"
        style={{
          minHeight: "700px",
        }}
      >
        <Routes>
          <Route path="/games" element={<Games />} />
          <Route path="/gamesform" element={<GamesForm />} />
          <Route path="/console/:subscription_id/*" element={<Console />} />
          <Route path="/account/*" element={<Account />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/games/:game_slug" element={<Game />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}
