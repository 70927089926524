import ProList from "@ant-design/pro-list";
import ProTable from "@ant-design/pro-table";
import { Avatar, Button, Select, Tag } from "antd";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useConsole } from "..";

function ModEnable({ mod }) {
  const { subscription, subscriptions_query } = useConsole();
  const { t } = useTranslation();

  const enable = useMutation(
    () =>
      axios.post(
        "/servers/" +
          subscription._id +
          "/tools/generic/mods/" +
          mod +
          "/enable"
      ),
    {
      onSuccess: () => {
        subscriptions_query.refetch();
      },
    }
  );
  return (
    <div className="flex gap-2">
      <Button
        className="text-white bg-green-500 border-green-500 shadow-green-500"
        type="primary"
        loading={enable.isLoading}
        onClick={() => enable.mutate()}
      >
        {t("enable")}
      </Button>
    </div>
  );
}

function ModDisable({ mod }) {
  const { subscription, subscriptions_query } = useConsole();
  const { t } = useTranslation();

  const disable = useMutation(
    () =>
      axios.post(
        "/servers/" +
          subscription._id +
          "/tools/generic/mods/" +
          mod +
          "/disable"
      ),
    {
      onSuccess: () => {
        subscriptions_query.refetch();
      },
    }
  );
  return (
    <div className="flex gap-2">
      <Button
        type="danger"
        loading={disable.isLoading}
        onClick={() => disable.mutate()}
      >
        {t("disable")}
      </Button>
    </div>
  );
}

export function Mods() {
  const { t } = useTranslation();
  const { subscription, subscriptions_query } = useConsole();
  const mods_query = useQuery(["mods", subscription._id], () =>
    axios.get("/servers/" + subscription._id + "/tools/generic/mods")
  );
  const mods = mods_query.data?.data || "";

  return (
    <div className="flex flex-col w-full h-full p-4 bg-white">
      {subscription.modpack ? (
        <ProList
          headerTitle="Mods Manager"
          metas={{
            avatar: {
              dataIndex: "logo",
              render: (text, record, _, action) => {
                return <Avatar shape="square" size="large" src={text} />;
              },
            },
            title: {
              dataIndex: "name",
            },
            actions: {
              render: (text, record, _, action) => {
                return subscription.disabled_mods.includes(
                  record.id.toString()
                ) ? (
                  <ModEnable mod={record.id} />
                ) : (
                  <ModDisable mod={record.id} />
                );
              },
            },
          }}
          dataSource={mods}
        />
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <h1 className="text-2xl font-bold">{t("no_modpack_selected")}</h1>
          <p className="text-lg">{t("no_modpack_selected_description")}</p>
        </div>
      )}
    </div>
  );
}
