import { Alert, Button, Form, message, Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { normalize_query_error } from "../../../components/adhoc/normalize_error";

import { CreateGameForm } from "../forms/CreateGameForm";

export function CreateGame() {
  const { t } = useTranslation();
  const [visible, set_visible] = useState(false);
  const [form] = Form.useForm();

  const client = useQueryClient();

  const create_game = useMutation(
    (value) => {
      const form_data = new FormData();
      Object.keys(value).forEach((k) => {
        form_data.append(k, value[k]);
      });
      return axios.post("/games", form_data);
    },
    {
      onSuccess: () => {
        message.success(t("game.create.success"));
        client.invalidateQueries("games");
        set_visible(false);
        form.resetFields();
      },
    }
  );
  const create_game_error = normalize_query_error(create_game.error);

  return (
    <>
      <Button type="primary" onClick={() => set_visible(true)}>
        {t("game.create")}
      </Button>
      <Modal
        visible={visible}
        centered
        onCancel={() => set_visible(false)}
        onOk={() => {
          form.validateFields().then((value) => {
            create_game.mutate(value);
          });
        }}
        okText={t("game.create")}
        destroyOnClose
      >
        <CreateGameForm form={form} error={create_game_error?.entities} />
        {create_game_error?.generic && (
          <Alert
            block
            type="error"
            showIcon
            message={t(create_game_error?.generic)}
          />
        )}
      </Modal>
    </>
  );
}
