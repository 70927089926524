import { Button } from "antd";
import ServersIcon from "@mui/icons-material/DnsRounded";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import axios from "axios";
import { useConsole } from "..";
import DeleteIcon from '@mui/icons-material/DeleteRounded';

export function DeleteSubscription() {
    const { t } = useTranslation();
    const { subscription, subscriptions_query } = useConsole();
  
    const delete_subscription = useMutation(
      (game) =>
        axios.delete("/servers/" + subscription._id),
      {
        onSuccess: () => {
          subscriptions_query.refetch();
        },
      }
    );
  
    return (
      <>
        <Button
          size="large"
          block
          type="danger"
          className="space-x-4 text-left"
          loading={delete_subscription.isLoading}
          onClick={() => delete_subscription.mutate()}
        >
          <ServersIcon />
          <span className="flex-grow">{t("subscription.delete")}</span>
        </Button>
      </>
    );
  }
  