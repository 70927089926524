import { ProFormText, ProFormTextArea } from "@ant-design/pro-components";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { ImageUpload } from "../../../components/input/ImageUpload";
import _ from "lodash";

export function CreateGameForm({ form, error }) {
  const { t } = useTranslation();
  return (
    <Form form={form} layout="vertical">
      <ProFormText
        name="name"
        label={t("game.name")}
        rules={[{ required: true, message: t("name_required") }]}
        validateStatus={_.get(error, "name") ? "error" : undefined}
        help={_.get(error, "name") ? error.name : undefined}
        placeholder={t("game.name_placeholder")}
      />
      <ProFormText
        name="image"
        label={t("game.image")}
        rules={[{ required: true, message: t("image_required") }]}
        validateStatus={_.get(error, "image") ? "error" : undefined}
        help={_.get(error, "image") ? error.image : undefined}
        placeholder={t("game.artwork_placeholder")}
      />
      <ProFormTextArea
        name="description"
        label={t("game.description")}
        rules={[{ required: true, message: t("description_required") }]}
        validateStatus={_.get(error, "description") ? "error" : undefined}
        help={_.get(error, "description") ? error.description : undefined}
        placeholder={t("game.description_placeholder")}
      />
      <Form.Item
        name="artwork"
        label={t("game.artwork")}
        rules={[{ required: true, message: t("artwork_required") }]}
        validateStatus={_.get(error, "artwork") ? "error" : undefined}
        help={_.get(error, "artwork") ? error.artwork : undefined}
      >
        <ImageUpload />
      </Form.Item>
    </Form>
  );
}
