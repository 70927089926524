import { Loader } from "./Loader"
import { RequestGenericErrorAlert } from "./Alert"

export function QueryLoader({ children, query }) {
  switch (query.status) {
    case "success":
      return children;
    case "loading":
      return <Loader />;
    case "error":
      return <RequestGenericErrorAlert error={query.error} />;
    default:
      break;
  }
}
