import { Alert, Button, Form, message, Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { normalize_query_error } from "../../../components/adhoc/normalize_error";

import { CreateCouponForm } from "../forms/CreateCouponForm";

export function CreateCoupon() {
  const { t } = useTranslation();
  const [visible, set_visible] = useState(false);
  const [form] = Form.useForm();

  const client = useQueryClient()

  const create_coupon = useMutation(
    (value) => {
      return axios.post("/coupons", value);
    },
    {
      onSuccess: () => {
        message.success(t("coupon.create.success"));
        client.invalidateQueries("coupons");
        set_visible(false);
        form.resetFields();
      },
    }
  );
  const create_coupon_error = normalize_query_error(create_coupon.error);

  return (
    <>
      <Button type="primary" onClick={() => set_visible(true)}>
        {t("coupon.create")}
      </Button>
      <Modal
        visible={visible}
        centered
        onCancel={() => set_visible(false)}
        onOk={() => {
          form.validateFields().then((value) => {
            create_coupon.mutate(value);
          });
        }}
        okText={t("coupon.create")}
        destroyOnClose
      >
        <CreateCouponForm form={form} error={create_coupon_error?.entities} />
        {create_coupon_error?.generic && (
          <Alert
            block
            type="error"
            showIcon
            message={t(create_coupon_error?.generic)}
          />
        )}
      </Modal>
    </>
  );
}
