import axios from "axios";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { GameCard } from "../homepage/GamesShowcaseSlider";
import { GamesSearch } from "./widgets/GamesSearch";
import { Hero } from "./widgets/Hero";

export function Games() {
  const [name, set_name] = useState();

  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  const searched_games = useMemo(() => {
    if (name) {
      return games?.filter((game) => game.name.toLowerCase().includes(name));
    }
    return [...games];
  }, [name, games]);

  return (
    <div className="mx-auto">
      <Hero />
      <GamesSearch value={name} onChange={(e) => set_name(e.target.value)} />
      <div className="container grid grid-cols-4 mx-auto my-16">
        {searched_games.map((game, i) => {
          return <GameCard key={i} game={game} />;
        })}
      </div>
    </div>
  );
}
