import { useTranslation } from "react-i18next";
import { AutoComplete } from "antd";
import { useNavigate } from "react-router-dom";
import { useState, useCallback } from "react";
import { useQuery } from "react-query";
import { CloseSquareFilled } from '@ant-design/icons';
import axios from "axios";
import debounce from "lodash.debounce";

export function DirectCallToAction() {
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Query to fetch games based on the search text
  const { data: games = [] } = useQuery(
    ["games", searchText],
    () => axios.get(`/games/search?query=${searchText}`).then((res) => res.data),
    {
      enabled: !!searchText, // Only run this query when searchText is not empty
      onSuccess: (data) => {
        const newOptions = data.map((game) => ({
          value: game.name,
          label: game.name,
        }));
        setOptions(newOptions);
      },
    }
  );

  const onSelect = (data) => {
    navigate(`/games/${data}`);
  };

  const onSearch = useCallback(
    debounce((text) => {
      setSearchText(text); // Update the search text
    }, 500), // 500 ms delay
    []
  );

  return (
    <div
      className=""
      style={{
        backgroundImage: `url(/assets/direct-call-to-action-background.png)`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-col items-center justify-center w-full gap-8 p-4 py-44 backdrop-brightness-50">
        <h1 className="text-4xl text-center text-white capitalize">
          {t("homepage.call_to_action.title")}
        </h1>

        <AutoComplete
          options={options}
          className="w-full max-w-xl text-lg"
          onSelect={onSelect}
          onSearch={onSearch} // Use onSearch handler to update searchText
          placeholder="Search..."
          allowClear={{ clearIcon: <CloseSquareFilled /> }}
        />
        {/* <Button>{t("direct_call_to_action.button")}</Button> */}
      </div>
    </div>
  );
}