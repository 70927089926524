import axios from "axios";
import { createContext, useContext } from "react";
import { useQuery } from "react-query";
import { useAuthentication } from ".";

const context = createContext();

export const UserProvider = ({ children }) => {
  const { is_authenticated } = useAuthentication();

  const user_query = useQuery("me", () => axios.get("/users/me"), {
    enabled: is_authenticated === true,
  });

  const granted = (action, resource) => {
    const user = user_query.data?.data;
    if (!user) return;
    return user.grants.find(
      (grant) =>
        (grant.resource.includes(resource) || grant.resource.includes("*")) &&
        (grant.action.includes(action) || grant.action.includes("*"))
    );
  };

  return (
    <context.Provider
      value={{
        user: user_query.data?.data,
        status: user_query.status,
        error: user_query.error,
        refetch: user_query.refetch,
        granted,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useUser = () => useContext(context);
