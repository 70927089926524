import { useTranslation } from "react-i18next";
import { Footer } from "../homepage/Footer";
import { Navbar } from "../homepage/Navbar";
import { ContactCard } from "../components/controllerpursuant/ContactCard";

export function Toc() {
    const { t } = useTranslation();
    return (
        <div className=" bg-background space-y-12">
            <Navbar />
            <section className="container mx-auto p-4 flex justify-between items-start gap-[2rem]">
                {/* Container */}
                <div>
                    <div>
                        <h1 className="text-white text-[3rem] border-b-4 border-light-1 inline-block">{t(`toc`)}</h1>
                        <h1 className="text-white text-[24px]">{t(`toc.definitions`)}</h1>
                        <div className="text-[#E4E4E4] bg-[#2F1F58] border-l-4 border-light-1 shadow-bsh flex justify-arround items-center gap-8 pt-3 px-3 mb-6">
                            <h1 className="text-light-1 text-[3rem] mt-2">?</h1>
                            <h1 className="text-[#7D7D7D] text-[2rem] font-thin ">|</h1>
                            <p>
                                {t(`toc.header`)}
                            </p>
                        </div>
                        {/* <p className="text-white">
                            <span className="text-light-1 font-bold">1</span> . Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <p className="text-white">
                            <span className="text-light-1 font-bold">2</span> . Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <p className="text-white">
                            <span className="text-light-1 font-bold">3</span> . Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <p className="text-white">
                            <span className="text-light-1 font-bold">4</span> . Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <p className="text-white">
                            <span className="text-light-1 font-bold">5</span> . Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p> */}
                    </div>
                </div>
                <ContactCard />

            </section>

            <Footer />
        </div>
    );
}