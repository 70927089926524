import { Alert, Button, Form, message, Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { normalize_query_error } from "../../../components/adhoc/normalize_error";
import { read, utils } from "xlsx";

import { CreatePriceForm } from "../forms/CreatePriceForm";

export function CreatePrice() {
  const { t } = useTranslation();
  const [visible, set_visible] = useState(false);

  const [form] = Form.useForm();

  const client = useQueryClient();

  const handleFileToJson = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        resolve(json);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const uploadPrices = async (values) => {
    const gameId = values.game;
    const josnData = (await handleFileToJson(values.file)) ?? [];
    const result = await Promise.allSettled(
      josnData.map(async (value) => {
        try {
          let form_data = {
            game: gameId,
            cycle: value?.cycle,
            price: value?.price,
            ram: value?.ram,
            slots: value?.slots,
          };
          return axios.post("/prices", form_data);
        } catch (err) {
          console.error(`I'm down, this time. ${err}`);
        }
      })
    );
    return await result;
  };

  const create_package = useMutation(
    async (value) => {
      return await uploadPrices(value);
    },
    {
      onSuccess: () => {
        message.success(t("package.create.success"));
        client.invalidateQueries("packages");
        set_visible(false);
        form.resetFields();
      },
    }
  );

  const create_package_error = normalize_query_error(create_package.error);

  return (
    <>
      <Button type="primary" onClick={() => set_visible(true)}>
        {t("package.create")}
      </Button>
      <Modal
        visible={visible}
        centered
        onCancel={() => set_visible(false)}
        onOk={() => {
          form.validateFields().then((value) => {
            create_package.mutate(value);
          });
        }}
        okText={t("package.create")}
        destroyOnClose
      >
        <CreatePriceForm form={form} error={create_package_error?.entities} />
        {create_package_error?.generic && (
          <Alert
            block
            type="error"
            showIcon
            message={t(create_package_error?.generic)}
          />
        )}
      </Modal>
    </>
  );
}
