import ProList from "@ant-design/pro-list";
import ProTable from "@ant-design/pro-table";
import { Button, Image, Tag } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { QueryLoader } from "../../components/utility/QueryLoader";

const status_color_map = {
  online: "green",
  offline: "red",
  starting: "blue",
  stopping: "blue",
};

export function Servers() {
  const servers_query = useQuery("subscriptions", () =>
    axios.get("/subscriptions")
  );
  const servers = servers_query.data?.data || [];

  return (
    <div className="w-full h-full">
      <QueryLoader query={servers_query}>
        <ProTable
          search={false}
          headerTitle="Servers"
          dataSource={servers}
          rowKey="_id"
          columns={[
            {
              title: "Game",
              render: (_, record) => (
                <div className="flex items-center">
                  <div
                    className="h-8 mr-2 rounded aspect-square"
                    style={{
                      backgroundImage: `url(${record.game.artwork})`,
                      backgroundSize: "cover",
                    }}
                  />

                  <Link to={`/games/${record.game.name}`}>
                    {record.game ? record.game.name : "Unknown"}
                  </Link>
                </div>
              ),
              search: false,
              width: "70%",
            },
            {
              title: "Actions",
              render: (_, record) => {
                return (
                  <div className="space-x-2">
                    <Link to={
                      `/console/${record._id}`
                    }>
                    <Button type="primary">configure</Button>
                    </Link>
                  </div>
                );
              },
            },
            {
              title: "contId",
              render: (_, record) => {
                return (
                  <div className="space-x-2">
                    container: {record.container.substring(0, 8) ?? "n/a"}
                    
                   
                  </div>
                );
              },
            },
          ]}
        />
      </QueryLoader>
    </div>
  );
}
