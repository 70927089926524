import { useTranslation } from "react-i18next";


export function ContactCard({className}) {
    const { t } = useTranslation();
    return (
        <div className={`bg-[#442d80] text-white border-l-4 border-light-1 mt-[9.5rem] w-full p-4 ${className}`}>
            <h1 className="text-white text-[16px] font-bold">{t(`controllerpursuant.main_heading`)}</h1>
            <p className="text-[12px] font-light">{t(`controllerpursuant.phone`)}: +49 (0) 721 7540 44 44</p>
            <p className="text-[12px] font-light">{t(`controllerpursuant.fax`)}: 0800 100 3860</p>
            <p className="text-[12px] font-light">{t(`controllerpursuant.email`)}: <span className="text-[#793FF9] font-medium">info@nitrado.net</span></p>
            <h1 className="text-white text-[16px] font-bold">{t(`controllerpursuant.heading`)}</h1>
            <p className="text-[12px] font-light">{t(`controllerpursuant.phone`)}: +49 (0) 721 7540 44 44</p>
            <p className="text-[12px] font-light">{t(`controllerpursuant.email`)}: <span className="text-[#793FF9] font-medium">info@nitrado.net</span></p>
        </div>
    );
}