import ProList from "@ant-design/pro-list";
import { Button, Input, Modal, Tag } from "antd";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useConsole } from "..";

function ActivateWorld({ world }) {
  const { t } = useTranslation();
  const { subscription, subscriptions_query } = useConsole();

  const activate = useMutation(
    () =>
      axios.patch(
        "/servers/" + subscription._id + "/tools/generic/worlds/" + world.name
      ),
    {
      onSuccess: () => {
        subscriptions_query.refetch();
      },
    }
  );

  return (
    <Button
      type="primary"
      loading={activate.isLoading}
      onClick={() => {
        activate.mutate();
      }}
    >
      {t("world.activate")}
    </Button>
  );
}

function DeleteWorld({ world }) {
  const { t } = useTranslation();
  const { subscription, subscriptions_query } = useConsole();

  const belete = useMutation(
    () =>
      axios.delete(
        "/servers/" + subscription._id + "/tools/generic/worlds/" + world.name
      ),
    {
      onSuccess: () => {
        subscriptions_query.refetch();
      },
    }
  );

  return (
    <Button
      type="danger"
      loading={belete.isLoading}
      onClick={() => {
        belete.mutate();
      }}
    >
      {t("world.delete")}
    </Button>
  );
}

function ImportWorld() {
  const { t } = useTranslation();
  const [visible, set_visible] = useState(false);
  const [world, set_world] = useState(null);
  const { subscription, subscriptions_query } = useConsole();

  const imqort = useMutation(
    (world) => {
      const body = new FormData();
      body.append("world", world);
      return axios.post(
        "/servers/" + subscription._id + "/tools/generic/worlds/import",
        body
      );
    },
    {
      onSuccess: () => {
        subscriptions_query.refetch();
        set_visible(false);
      },
    }
  );

  return (
    <>
      <Button onClick={() => set_visible(true)} type="primary">
        {t("world.import")}
      </Button>
      <Modal
        visible={visible}
        centered
        onCancel={() => set_visible(false)}
        onOk={() => {
          imqort.mutate(world);
        }}
        okText={t("world.import")}
        destroyOnClose
        okButtonProps={{
          disabled: !world,
          loading: imqort.isLoading,
        }}
      >
        <div className="py-6 text-white">
          <Input
            type={"file"}
            onChange={(e) => {
              set_world(e.target.files[0]);
            }}
          />
        </div>
      </Modal>
    </>
  );
}

export function Worlds() {
  const { t } = useTranslation();
  const { subscription } = useConsole();

  console.log(subscription.worlds)

  return (
    <div className="flex flex-col w-full h-full p-4 bg-white">
      <ProList
        headerTitle="Worlds"
        toolBarRender={() => [<ImportWorld key='import' />]}
        metas={{
          title: {
            dataIndex: "name",
          },
          description: {
            dataIndex: "created_at",
          },
          subTitle: {
            dataIndex: "id",
            render: (text, record, _, action) => {
              return (
                subscription.active_world == record.name && (
                  <Tag color="green">{t("active")}</Tag>
                )
              );
            },
          },
          actions: {
            render: (text, record, _, action) => [
              subscription.active_world != record.name && <ActivateWorld key='activate' world={record} />,
              subscription.active_world != record.name && <DeleteWorld key='delete' world={record} />,
            ],
          },
        }}
        rowKey="name"
        dataSource={subscription.worlds}
      />
    </div>
  );
}
