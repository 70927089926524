import UploadIcon from "@mui/icons-material/FileUploadRounded";
import { useTranslation } from "react-i18next";

export function ImageUpload({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <label
        className="inline-block w-full p-1 duration-200 border border-dashed cursor-pointer hover:bg-gray-300 h-44"
        htmlFor="image-upload"
      >
        {value ? (
          <div
            className="w-full h-full rounded"
            style={{
              backgroundImage: `url(${URL.createObjectURL(value)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full gap-4">
            <UploadIcon />
            <span className="text-gray-600">{t("image.upload")}</span>
          </div>
        )}
      </label>
      <input
        type="file"
        id="image-upload"
        className="hidden"
        onChange={(value) => {
          onChange(value.target.files[0]);
        }}
      />
    </div>
  );
}
