import { Navbar } from "./Navbar";

export function Hero() {
  return (
    <div
      className="px-4 rounded-b"
      style={{
        backgroundImage: `url(/assets/hero_background.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "75vh"
      }}
    >
      <Navbar />
    </div>
  );
}
