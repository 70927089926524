import axios from "axios";
import { useQuery } from "react-query";
import { useConsole } from "..";
import { QueryLoader } from "../../components/utility/QueryLoader";
import { XTerm } from 'xterm-for-react'
import { useEffect, useRef } from "react";

export function Logs() {
  const { subscription } = useConsole();
  const ref = useRef()

  const logs_query = useQuery(["logs", subscription._id], () =>
    axios.get("/servers/" + subscription._id + "/tools/generic/logs"),
    {
        refetchInterval: 1000,
    }
  );
  const logs = logs_query.data?.data || "";

  useEffect(() => {
    if (ref.current) {
      ref.current.terminal.write(logs)
      ref.current.terminal.scrollToBottom()
    }
  }, [logs])

  return (
    <QueryLoader query={logs_query}>
      <div className="flex flex-col w-full h-full p-4 bg-white">
        <XTerm ref={ref} options={{
          cursorBlink: true,
          cursorStyle: "underline",
          cols: 120,
          rows: 48,
          fontSize: 10,
          convertEol: true,
        }} />
        {/* <div className="flex-grow h-0 overflow-y-auto bg-neutral-900 text-neutral-100">{logs}</div> */}
      </div>
    </QueryLoader>
  );
}
