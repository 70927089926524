import { Button } from "antd";
import ServersIcon from "@mui/icons-material/DnsRounded";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useConsole } from "..";
import DeleteIcon from "@mui/icons-material/DeleteRounded";

export function DeletePackage({ package: value }) {
  const { t } = useTranslation();
  const client = useQueryClient();
  const delete_package = useMutation(
    () => axios.delete("/packages/" + value._id),
    {
      onSuccess: () => {
        client.invalidateQueries("packages");
      },
    }
  );

  return (
    <>
      <Button
        type="danger"
        className="space-x-4 text-left"
        loading={delete_package.isLoading}
        onClick={() => delete_package.mutate()}
      >
        <span className="flex-grow">{t("package.delete")}</span>
      </Button>
    </>
  );
}
