import ProList from "@ant-design/pro-list";
import { Image, Tag } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { QueryLoader } from "../../components/utility/QueryLoader";
import { useMemo, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import ProTable from "@ant-design/pro-table";

const transaction_event_color_map = {
  SUBSCRIPTION_CREATED: "blue",
  SUBSCRIPTION_PAID: "green",
  SUBSCRIPTION_DELETED: "red",
};

function TransactionsList() {
  const { t } = useTranslation();
  const [filter, set_filter] = useState({});

  const transactions_query = useQuery("transactions", () =>
    axios.get("/transactions")
  );
  const transactions = transactions_query.data?.data || [];

  const filtered_transactions = useMemo(() => {
    if (!filter.status) {
      return transactions;
    }
    return transactions.filter((pkg) => {});
  }, [filter, transactions]);

  return (
    <QueryLoader query={transactions_query}>
      <ProTable
        headerTitle={t("transactions")}
        dataSource={filtered_transactions}
        search={{
          filterType: "light",
        }}
        request={set_filter}
        columns={[
          {
            title: t("transaction.id"),
            dataIndex: "code",
            search: false,
          },
          {
            title: t("transaction.event"),
            dataIndex: "event",
            valueType: "select",
            render: (value, record) => (
              <Tag color={transaction_event_color_map[record.event]}>
                {t(`transaction.event.${record.event}`)}
              </Tag>
            ),
          },
          {
            title: t("transaction.user"),
            dataIndex: ["user", "name"],
            search: false,
          },
          {
            title: t("transaction.date"),
            dataIndex: "createdAt",
            search: false,
            render: (value) => new Date(value).toLocaleString(),
          },
        ]}
      />
    </QueryLoader>
  );
}

export function Transactions() {
  return (
    <div className="flex-grow">
      <TransactionsList />
    </div>
  );
}
