import axios from "axios";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { GamePackage } from "../components/subscription/GamePackage";
import { QueryLoader } from "../components/utility/QueryLoader";

export function Game() {
  const { t } = useTranslation();
  const { game_slug } = useParams();

  const games_query = useQuery("games", () => axios.get("/games"));
  const packages_query = useQuery("packages", () => axios.get("/packages"));

  const game = useMemo(() => {
    const games = games_query.data?.data ?? [];
    return games.find((game) => game.name === game_slug);
  }, [game_slug, games_query.data?.data]);

  const game_packages = useMemo(() => {
    let pkgs = [];
    const packages = packages_query.data?.data ?? [];
    if (game && packages.length > 0) {
      pkgs = packages.filter((pkg) => pkg.game === game?._id);
    }
    return pkgs;
  }, [game, packages_query.data?.data]);

  return (
    <div className="my-16">
      <QueryLoader query={games_query}>
        {/* Empty placeholder */}
        {!game && (
          <div className="flex items-center justify-center h-max">
            {t("game_not_found")}
          </div>
        )}

        {game && (
          <div className="container w-full px-4 mx-auto space-y-16">
            <div className="grid grid-rows-3 gap-4 lg:grid-cols-3 h-[600px]">
              <div
                className="col-span-1 row-span-3 rounded-md lg:col-span-2"
                style={{
                  backgroundImage: `url(${game.artwork})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="w-full row-span-3 p-4 h-full rounded-md bg-[#2F1F58]">
                <h1 className="text-xl text-white lg:text-2xl">{game.name}</h1>
                <p className="text-base text-white lg:text-lg">
                  {game.description}
                </p>
              </div>
            </div>
            <div className="grid gap-6 mx-auto sm:grid-cols-2 w-fit 2xl:grid-cols-4">
              {game_packages.map((qackage, index) => {
                return (
                  <GamePackage
                    readOnly
                    game={game}
                    plan={qackage}
                    action={t("plan.pick")}
                    key={index}
                  />
                );
              })}
              <GamePackage
                readOnly
                startingFrom
                game={game}
                plan={{
                  _id: "custom",
                  name: t("plan.custom"),
                  price: 6.99,
                }}
                entitlements={[
                  "4 to 200 players",
                  "Individual runtime",
                  "Available immediately",
                ]}
                action={t("plan.customize")}
              />
            </div>
          </div>
        )}
      </QueryLoader>
    </div>
  );
}
