import { Button, Input } from "antd";
import axios from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { useConsole } from "..";

export function RCONConsole(params) {
  const { subscription } = useConsole();
  const [prompt, set_prompt] = useState("");
  const [history, set_history] = useState([]);

  const send = useMutation(
    (prompt) =>
      axios.post(
        "/servers/" + subscription._id + "/tools/generic/rcon",
        prompt
      ),
    {
      onSuccess: (response) => {
        set_history((history) => [
          ...history,
          {
            prompt: prompt,
            response: response.data,
          },
        ]);
        set_prompt("");
      },
    }
  );
  return (
    <div className="flex flex-col w-full h-full p-4 bg-white">
      <div className="flex flex-col flex-grow p-2 rounded-t bg-neutral-900 text-neutral-50">
        <div className="flex-grow h-0 overflow-auto">
          {history.map((entry, index) => (
            <div className="flex flex-col" key={index}>
              <span className="font-bold text-green-500">{"> "}{entry.prompt}</span>
              <span className="text-neutral-300">{entry.response}</span>
            </div>
          ))}
        </div>
        <div className="flex">
          <Input
            value={prompt}
            onChange={(e) => set_prompt(e.target.value)}
            className="flex-grow"
          />
          <Button
            onClick={() => {
              send.mutate();
            }}
            loading={send.isLoading}
          >
            send
          </Button>
        </div>
      </div>
    </div>
  );
}
