import { useTranslation } from "react-i18next";
import { Footer } from "../homepage/Footer";
import { Navbar } from "../homepage/Navbar";
import { ContactCard } from "../components/controllerpursuant/ContactCard";

export function Privacy() {
    const { t } = useTranslation();
    return (
        <div className = "space-y-12 bg-background">  
            <Navbar />
            <section className="container mx-auto p-4 flex flex-col md:flex-row justify-between items-start sm:gap-x-[2rem]">
            {/* Container */}
                    <div>
                        <div>
                            <h1 className="text-white text-[3rem] border-b-4 border-light-1 inline-block">{t(`privacy`)}</h1>
                            <h1 className="text-white text-[24px]">{t(`privacy.definitions`)}</h1>
                            <div className="text-[#E4E4E4] bg-[#2F1F58] border-l-4 border-light-1 shadow-bsh flex justify-arround items-center gap-8 pt-3 px-3 mb-6">
                                <h1 className="text-light-1 text-[3rem] mt-2">?</h1>
                                <h1 className="text-[#7D7D7D] text-[2rem] font-thin ">|</h1>
                                <p>
                                {t(`privacy.header`)}
                                </p>
                            </div>
                            <p className="text-white">
                            <span className="font-bold text-light-1">1</span>. {t(`privacy.paragraph`)}
                            </p>
                        </div>
                    </div>
                    <ContactCard className={'order-1'} />
            </section>

            <Footer /> 
        </div>
    );
}