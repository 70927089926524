import { Features } from "./Features";
import { Hero } from "./Hero";
import { GamesShowcaseSlider } from "./GamesShowcaseSlider";
import { GamesThumbnailsSlider } from "./GamesThumbnailsSlider";
import { StepsCallToAction } from "./StepsCallToAction";
import { DirectCallToAction } from "./DirectCallToAction";
import { Footer } from "./Footer";
import { Banner } from "./Banner";

const Homepage = () => {
  return (
    <div className="w-full h-full space-y-16 overflow-y-auto bg-background">
      <div className="">
        <Hero />
        <GamesThumbnailsSlider />
      </div>
      <GamesShowcaseSlider />
      <Banner title={"homepage.banner.title"} />
      <StepsCallToAction />
      <Features />
      <Banner title={"homepage.banner2.title"} />
      <DirectCallToAction />
      <Footer />
    </div>
  );
};

export default Homepage;
