import firebase from "firebase/compat/app";

export const application = firebase.initializeApp({
  apiKey: "AIzaSyDySgjh09hg_PQmfg9TwchnZZX4rPUgt6o",
  authDomain: "serferaty.firebaseapp.com",
  projectId: "serferaty",
  storageBucket: "serferaty.appspot.com",
  messagingSenderId: "503709876744",
  appId: "1:503709876744:web:d1c295c4640760aa3107bd",
  measurementId: "G-DCKVHG8T1C"
});
