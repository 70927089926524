import { Button, Card, Statistic, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useConsole } from "..";
import { IF } from "../../components/adhoc/IF";
import { CPUUsageChart } from "./CPUUsageChart";
import { MemoryUsageChart } from "./MemoryUsageChart";
import { PlayersChart } from "./PlayersChart";

export const status_color_map = {
  running: "green",
  exited: "red",
};

export function Dashboard() {
  const { t } = useTranslation();
  const { informations, start, stop, restart } = useConsole();

  return (
    <div className="w-full h-full p-4 space-y-4 bg-white">
      <h1>
        Dashboard{" "}
        <Tag color={status_color_map[informations.status]}>
          {informations.status}
        </Tag>
      </h1>
      <div className="flex items-start gap-2">
        <span className="flex-grow">
          Here you can find information regarding your server. there are more
          options on the left-side panel.
        </span>
        <div className="flex items-start gap-2">
          {informations.status !== "running" && (
            <Button
              loading={start.isLoading}
              onClick={() => start.mutate()}
              className="text-white bg-green-500 border-green-500 shadow-green-500"
            >
              {t("server.start")}
            </Button>
          )}
          {informations.status === "running" && (
            <Button
              loading={stop.isLoading}
              onClick={() => stop.mutate()}
              type="danger"
            >
              {t("server.stop")}
            </Button>
          )}
          <Button
            loading={restart.isLoading}
            onClick={() => restart.mutate()}
            className="text-white bg-yellow-500 border-yellow-500 shadow-yellow-500"
          >
            {t("server.restart")}
          </Button>
        </div>
      </div>
      <div>
        <h2>{t("plan")}</h2>
        <div className="grid grid-cols-4 gap-4">
          <Card>
            <Statistic
              title={t("slots")}
              value={informations.cpu ?? "n/a"}
              suffix="%"
            />
          </Card>
          <Card>
            <Statistic
              title={t("ram")}
              value={informations.ram ?? "n/a"}
              suffix="GB"
            />
          </Card>
          <Card>
            <Statistic
              title={t("days")}
              value={informations.players ?? "n/a"}
              suffix="/ 100"
            />
          </Card>
          <Card>
            <Statistic
              title={t("price")}
              value={informations.players ?? "n/a"}
              suffix="/ 100"
            />
          </Card>
        </div>
      </div>
      <div>
        <h2>Server Ports</h2>
        <div className="grid grid-cols-3 gap-4">
          <Card>
            <Statistic
              title={t("server cpu")}
              value={informations.cpu ?? "n/a"}
              suffix="%"
            />
          </Card>
          <Card>
            <Statistic
              title={t("server ram")}
              value={informations.ram ?? "n/a"}
              suffix="GB"
            />
          </Card>
          <Card>
            <Statistic
              title={t("server players")}
              value={informations.players ?? "n/a"}
              suffix="/ 100"
            />
          </Card>
        </div>
      </div>
      <div>
        <h2>Server Ports</h2>
        <div className="grid grid-cols-3 gap-4">
          <Card>
            <Statistic
              title={t("server cpu")}
              value={informations.cpu ?? "n/a"}
              suffix="%"
            />
          </Card>
          <Card>
            <Statistic
              title={t("server ram")}
              value={informations.ram ?? "n/a"}
              suffix="GB"
            />
          </Card>
          <Card>
            <Statistic
              title={t("server players")}
              value={informations.players ?? "n/a"}
              suffix="/ 100"
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
