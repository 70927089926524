import { Alert, Button, Form, message, Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { normalize_query_error } from "../../../components/adhoc/normalize_error";

import { UpdatePackageForm } from "../forms/UpdatePackageForm";

export function UpdatePackage({ package: value }) {
  const { t } = useTranslation();
  const [visible, set_visible] = useState(false);
  const [form] = Form.useForm();

  const client = useQueryClient();

  const update_package = useMutation(
    (update) => {
      return axios.patch("/packages/" + value._id, update);
    },
    {
      onSuccess: () => {
        message.success(t("package.update.success"));
        client.invalidateQueries("packages");
        set_visible(false);
        form.resetFields();
      },
    }
  );
  const update_package_error = normalize_query_error(update_package.error);

  return (
    <>
      <Button type="primary" onClick={() => set_visible(true)}>
        {t("package.update")}
      </Button>
      <Modal
        visible={visible}
        centered
        onCancel={() => set_visible(false)}
        onOk={() => {
          form.validateFields().then((value) => {
            update_package.mutate(value);
          });
        }}
        okText={t("package.update")}
        destroyOnClose
        className="z-50"
      >
        <UpdatePackageForm
          value={value}
          form={form}
          error={update_package_error?.entities}
        />
        {update_package_error?.generic && (
          <Alert
            block
            type="error"
            showIcon
            message={t(update_package_error?.generic)}
          />
        )}
      </Modal>
    </>
  );
}
