import ProList from "@ant-design/pro-list";
import ProTable from "@ant-design/pro-table";
import { Avatar, Button, Select, Tag } from "antd";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useConsole } from "..";

function ModpackInstall({ modpack }) {
  const { subscription, subscriptions_query } = useConsole();
  const { t } = useTranslation();
  const [version, set_version] = useState(modpack.versions[0].id);

  const install = useMutation(
    ({modpack, version}) =>
      axios.post(
        "/servers/" +
          subscription._id +
          "/tools/generic/modpacks/" +
          modpack.id +
          "/" +
          version
      ),
    {
      onSuccess: () => {
        subscriptions_query.refetch();
      },
    }
  );
  return (
    <div className="flex gap-2">
      <Select
        value={version}
        onChange={(value) => set_version(value)}
        style={{ width: 120 }}
        options={modpack.versions.map((version) => ({
          value: version.id,
          label: version.name,
        }))}
      />
      <Button
        type="primary"
        loading={install.isLoading}
        onClick={() => install.mutate({modpack, version})}
      >
        {t("install")}
      </Button>
    </div>
  );
}

export function Modpacks() {
  const { t } = useTranslation();
  const { subscription, subscriptions_query } = useConsole();
  const modpacks_query = useQuery(["modpacks", subscription._id], () =>
    axios.get("/servers/" + subscription._id + "/tools/generic/modpacks")
  );
  const modpacks = modpacks_query.data?.data || "";

  return (
    <div className="flex flex-col w-full h-full p-4 bg-white">
      <ProList
        headerTitle={t("modpacks")}
        metas={{
          avatar: {
            dataIndex: "logo",
            render: (text, record, _, action) => {
              return <Avatar shape="square" size="large" src={text} />;
            },
          },
          subTitle: {
            dataIndex: "id",
            render: (text, record, _, action) => {
              return (
                subscription.modpack == text && (
                  <Tag color="green">{t("installed")}</Tag>
                )
              );
            },
          },
          title: {
            dataIndex: "name",
          },
          actions: {
            render: (text, record, _, action) => {
              return <ModpackInstall modpack={record} />;
            },
          },
        }}
        dataSource={modpacks}
      />
    </div>
  );
}
