// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import Backend from "i18next-locize-backend";
// import LanguageDetector from "i18next-browser-languagedetector";

// const locizeOptions = {
//   projectId: "b9d9c081-7eab-4d19-9e01-d197094b44c2",
//   apiKey: "4e8e8056-b377-492a-b60b-397cb646c529", // YOU should not expose your apps API key to production!!!
//   referenceLng: "en",
// };
// i18n.use(Backend).use(initReactI18next).init({
//   lng: "en",
//   debug: true,
//   fallbackLng: "en",
//   backend: locizeOptions,
//   saveMissing: true,
// });

// export default i18n;



// code without using locize adil asad
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-locize-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import ar from  "./ar/translation.json"
import en from  "./en/translation.json"
import ur from  "./ur/translation.json"

const locizeOptions = {
  projectId: "79eecc6e-6532-4b6b-b8ba-7e30a62a04d2",
  apiKey: "dff72ebc-124a-4770-80ae-fdab06719a78", // YOU should not expose your apps API key to production!!!
  referenceLng: "en",
};
i18n.use(Backend).use(initReactI18next).init({
  lng: "en",
  debug: true,
  fallbackLng: "en",
  resources:{
    en: {
      translation: en
    },
    ar: { 
      translation: ar
    },
    ur: { 
      translation: ur
    }
  },
  //backend: locizeOptions,
  saveMissing: true,
  keySeparator: false,
});

export default i18n;
