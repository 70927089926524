import { ProFormSelect } from "@ant-design/pro-components";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { ImageUpload } from "../../../components/input/ImageUpload";
import _ from "lodash";
import { useQuery } from "react-query";
import axios from "axios";

export function CreatePriceForm({ form, error }) {
  const { t } = useTranslation();

  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  return (
    <Form form={form} layout="vertical">
      <ProFormSelect
        name="game"
        label={t("game")}
        options={games.map((game) => ({
          label: game.name,
          value: game._id,
        }))}
        rules={[{ required: true, message: t("game_required") }]}
        validateStatus={_.get(error, "game") ? "error" : undefined}
        help={_.get(error, "game") ? error.game : undefined}
        placeholder={t("game_placeholder")}
      />
      <Form.Item
        name="file"
        label={t("game.artwork")}
        rules={[{ required: true, message: t("artwork_required") }]}
        validateStatus={_.get(error, "file") ? "error" : undefined}
        help={_.get(error, "file") ? error.file : undefined}
      >
        <ImageUpload />
      </Form.Item>
    </Form>
  );
}
