import { useTranslation } from "react-i18next";

export function Banner({ title }) {
  const { t } = useTranslation();

  return (
    <div className="container w-full px-6 mx-auto ">
      <div className="p-2 rounded-sm bg-gradient-to-r from-pink to-purple">
        <div className="w-full h-full p-4 bg-background">
          <h1 className="m-0 text-xl text-center text-white capitalize sm:text-3xl">
            {t(`${title}`)}
          </h1>
        </div>
      </div>
    </div>
  );
}
