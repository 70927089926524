import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Loader } from "../../components/utility/Loader";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrderDetails from "../order/detail";
import PaymentForm from "./form";

const stripePromise = loadStripe(
  "pk_test_51MsQbVAEuJ2dDs2iQEcjzSpfCCb5rCzhtFDfh5mDwOgNsKZAIh4VwQCtlHPzp2STWgVfW0AtiI9EVKvy7b3dkUoS00wEYpPiSn"
);

const Payment = ({ game, subscription, onNext }) => {
  const { t } = useTranslation();
  const create_subscription = useMutation((value) =>
    axios.post("/subscriptions", subscription).then((res) => res.data)
  );
  // const getPlatform = create_subscription.data.create_subscription
  useEffect(() => create_subscription.mutate(), []);

  // Accessing the data returned by the mutation
  const checkPlatform = "tappay"; //create_subscription.data;

  if (create_subscription.isLoading) {
    return <Loader />;
  }

  if (create_subscription.isSuccess) {
    const subscriptionId = create_subscription.data._id; // Extracting the _id

    if (checkPlatform === "stripe") {
      return (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: create_subscription.data.paymentIntent.client_secret,
          }}
        >
          <div className="grid grid-cols-2 gap-16">
            <PaymentForm onNext={onNext} subscriptionId={subscriptionId} />
            <div className="px-4 py-10 space-y-8 shadow shadow-purple-400">
              <div className="w-full max-w-xl">
                <OrderDetails game={game} subscription={subscription} />
              </div>
              <div className="w-full border border-light-1"></div>
              <div className="flex justify-between w-full">
                <span className="text-xl capitalize">
                  {t("checkout.price")}
                </span>
                <span className="text-3xl text-light-1">
                  {subscription.price ?? 4.2} $
                </span>
              </div>
            </div>
          </div>
        </Elements>
      );
    } else {
      return (
        <div>
          <div className="grid grid-cols-2 gap-16">
            <PaymentForm onNext={onNext} subscriptionId={subscriptionId} />
            <div className="px-4 py-10 space-y-8 shadow shadow-purple-400">
              <div className="w-full max-w-xl">
                <OrderDetails game={game} subscription={subscription} />
              </div>
              <div className="w-full border border-light-1"></div>
              <div className="flex justify-between w-full">
                <span className="text-xl capitalize">
                  {t("checkout.price")}
                </span>
                <span className="text-3xl text-white">
                  ${subscription.price}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  return null;
};

export default Payment;
