import { Carousel } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { QueryLoader } from "../../components/utility/QueryLoader";

function GameWindowThumbnail({ name, artwork }) {
  return (
    <div
      className="w-full"
      style={{
        backgroundImage: `url(${artwork})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        aspectRatio: 0.75,
      }}
    >
      <div className="flex items-center justify-center w-full h-full backdrop-brightness-50">
        <h1 className="text-lg text-white">{name}</h1>
      </div>
    </div>
  );
}

export function GamesWindowSlider() {
  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  return (
    <div className="inline-block w-full max-w-md border-8 border-purple-700 border-opacity-50 rounded">
      <img src="/assets/window-header.svg" />
      <QueryLoader query={games_query}>
        <Carousel autoplay slidesToShow={1} draggable>
          {games.map((game) => (
            <GameWindowThumbnail key={game.name} {...game} />
          ))}
        </Carousel>
      </QueryLoader>
      <div className="h-8 bg-violet-400"></div>
    </div>
  );
}
