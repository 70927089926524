import { Carousel } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { useQuery } from "react-query";
import axios from "axios";
import { QueryLoader } from "../components/utility/QueryLoader";

export function GameCard({ game }) {
  const { t } = useTranslation();

  const packages_query = useQuery("packages", () => axios.get("/packages"));
  const packages = packages_query.data?.data || [];

  const starting_from = packages.reduce((acc, pkg) => {
    if (pkg.game === game._id) {
      if (!acc) {
        return pkg;
      }
      if (pkg.price < acc.price) {
        return pkg;
      }
    }
    return acc;
  }, null);

  return (
    <Link to={`/games/${game.name}`}>
      <div
        className="flex flex-col mx-2 my-1 mb-4 duration-200 rounded-md shadow cursor-pointer shadow-purple-900 hover:shadow-purple-500"
        style={{
          aspectRatio: "0.75",
        }}
      >
        <div
          className="flex-grow rounded-t-md background-zoom"
          style={{
            backgroundImage: `url(${game.artwork})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="flex items-center justify-center w-full h-full duration-300 rounded-md backdrop-brightness-50 hover:backdrop-brightness-100"></div>
        </div>
        <div className="py-6 space-y-6 text-center">
          <h1 className="text-lg text-white">{game.name}</h1>
          <div className="w-full px-4">
            <div
              className="w-full rounded bg-light-1"
              style={{ height: "2px" }}
            />
          </div>
          {starting_from && (
            <div className="flex items-center justify-center gap-1">
              <span className="text-gray">{t("from")}</span>
              <span className="text-3xl text-white">
                {starting_from.price.toFixed(2)}
              </span>
              <span className="text-3xl text-white">SR</span>
              <span className="text-gray">/</span>
              <span className="text-gray">{starting_from.cycle}</span>
              <span className="text-gray">{t("days")}</span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export function GamesShowcaseSlider() {
  const { t } = useTranslation();

  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  const categories = ["featured", "top", "latest"];
  const [category, set_category] = useState(categories[0]);

  return (
    <div className="container w-full p-4 mx-auto space-y-4">
      <div className="space-y-4">
        <div className="grid w-full grid-cols-3">
          {categories.map((_category, index) => (
            <div
              key={index}
              className={`col-span-1 pb-8 cursor-pointer text-2xl text-center text-white capitalize ${
                _category === category ? "border-b-light-1" : "border-b-gray"
              }`}
              style={{
                border: "2px solid transparent",
              }}
              onClick={() => set_category(_category)}
            >
              {t("category." + _category)}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-end gap-2">
          <Link to="/games" className="text-lg capitalize text-gray">
            <span>{t("all_games")}</span>
            <RightIcon />
          </Link>
        </div>
      </div>
      <QueryLoader query={games_query}>
        <Carousel
          slidesToShow={games.length > 4 ? 4 : games.length}
          draggable
          className="hidden md:block"
        >
          {games.map((game) => (
            <GameCard key={game._id} game={game} />
          ))}
        </Carousel>
        <Carousel
          slidesToShow={games.length > 4 ? 4 : games.length}
          draggable
          dotPosition="right"
          className="md:hidden"
        >
          {games.map((game) => (
            <GameCard key={game._id} game={game} />
          ))}
        </Carousel>
      </QueryLoader>
      <div className="hidden border-b-purple-700 border-b-gray-700"></div>
    </div>
  );
}
